import request from '@/utils/request'
import axios from 'axios'
//获取profileList
export function getProfileList(page, userId, type, name) {
  let newUrl
  if (!userId) {
    if (type) {
      newUrl = `/coachProfiles?page=${page}&count=9&${type}=${name}&user_id=`
    } else {
      newUrl = `/coachProfiles?page=${page}&count=9&user_id=`
    }
  } else {
    newUrl = `/coachProfiles?page=${page}&count=9&${type}=${name}&user_id=${userId}`
  }
  return request({
    method: 'GET',
    url: newUrl,
  })
}
// 获取用户profile
export function getUserCoachProfile(data) {
  return request({
    method: 'GET',
    url: '/user-coachProfile',
    data,
  })
}
// 保存用户profile
export function saveUserProfile(data) {
  return request({
    method: 'POST',
    url: '/coach-profile',
    data,
  })
}
// 获取profile detail
export function getProfileDetail(id, userId) {
  let newUrl = `/coachProfile/${id}?user_id=`
  if (userId) {
    newUrl = `/coachProfile/${id}?user_id=${userId}`
  }
  return request({
    method: 'GET',
    url: newUrl,
  })
}

export function getCategoriesList(data) {
  return request({
    method: 'GET',
    url: '/categories',
    data,
  })
}

export function getMyConfirmedJobs() {
  return request({
    method: 'GET',
    url: '/get-confirmedJobs',
  })
}

export function getSportTypes(data) {
  return request({
    method: 'GET',
    url: '/sport-types',
    data,
  })
}

export function SavedProfiles(id) {
  return request({
    method: 'PUT',
    url: `/user-savedProfile/${id}`,
  })
}

export function unSavedProfiles(id) {
  return request({
    method: 'PUT',
    url: `/user-unsavedProfile/${id}`,
  })
}

export function getSavedProfiles(page) {
  return request({
    method: 'GET',
    url: `/my-savedprofiles?page=${page}&count=9`,
  })
}

export function getSportsList(name) {
  return request({
    method: 'GET',
    url: `/sport-types`,
    params: {
      type: name,
    },
  })
}

export function getConfirmProfile(id) {
  return request({
    method: 'PUT',
    url: `/confirm-created/${id}`,
  })
}

export function getUnconfirmedJobs(userId) {
  return request({
    method: 'GET',
    url: `/get-unconfirmedJobs?user_id=${userId}`,
  })
}
